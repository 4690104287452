.text-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.text-box {
  position: relative;
  display: inline-block; /* Permite que la caja se ajuste al contenido */
  font-size: 24px;
  margin-bottom: 20px;
  white-space: pre-wrap; /* Permite saltos de línea dentro de la caja */
  border: 1px solid lightgray;
  padding: 8px;
  min-width: 300px;
  cursor: text;
  width: auto; /* Ajusta el ancho al contenido del texto */
  border-radius: 8px;
}

.text-letter {
  display: inline-block;
  position: relative;
}

.falling-letter {
  position: absolute;
  animation: fall 3s ease forwards;
}

.falling-initial {
  position: relative;
  animation: initialFall 0.5s ease forwards;
  white-space: pre-wrap; /* Permite que el texto de placeholder se ajuste con saltos de línea */
}

@keyframes fall {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 100px;
    opacity: 0;
  }
}

@keyframes initialFall {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: text;
}

input {
  font-size: 16px;
}
