.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  word-wrap: break-word; /* Para que las palabras largas se dividan y pasen a la siguiente línea */
  overflow-wrap: break-word; /* Comportamiento moderno, recomendado */
  white-space: normal; /* Asegura que el texto pueda saltar a la siguiente línea */
}

.boxsizingBorder {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  width: 90%;        
}

.select-size  input{
  display: none;
}

label {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #ddd;
  line-height: 50px;
  cursor: pointer
}

#small:checked ~ label[for="small"],
#medium:checked ~ label[for="medium"],
#large:checked ~ label[for="large"],
#x-large:checked ~ label[for="x-large"],
#xx-large:checked ~ label[for="xx-large"] {
  background: #999;
  color: #ffffff;
}

.selected {
  background: #999;
  color: #ffffff;
}

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Ocultar barras de scroll */
  height: 100%; /* Asegurar que el body y el html ocupen el 100% de la altura */
}